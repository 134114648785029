import React from 'react';
import PageComponent from '../components/PageComponent';

import PageHeaderComponent from '../components/PageHeaderComponent';

import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"; //, LatLng

import { Button, ButtonGroup } from 'react-bootstrap';

import MapLocations from '../data/MapLocations.json';

import AssetNavigation from '../img/icons/navigation.png';

export default class MapPage extends PageComponent {


    defaultCenter = { lat: 33.086325, lng: -111.776858 };
    defaultZoom = 9;
    state = {
        filter: "all",
        center: this.defaultCenter,
        zoom: this.defaultZoom
    };
    map = null;
    googleMapsApiKey = 'AIzaSyAbogVY-L_kSISzQV01lJCmOmLzfWGwMK0';
    categories = {
        "all": { "label": "All", "icon": false },
        "Education": { "label": "Edu", "icon": "https://img.icons8.com/material-two-tone/64/000000/school.png" },
        "Emergency": { "label": "Emergency", "icon": "https://img.icons8.com/material-two-tone/64/000000/siren.png" },
        "Health": { "label": "Health", "icon": "https://img.icons8.com/material-two-tone/64/000000/survival-bag.png" },
        "District": { "label": "District", "icon": "https://img.icons8.com/material-two-tone/64/000000/marker.png" }
    };

    componentDidMount() {

    }

    render() {
        const MapComponent = withScriptjs(withGoogleMap((props) =>
            <GoogleMap
                defaultZoom={this.defaultZoom}
                defaultCenter={this.defaultCenter}
                ref={this.onMapLoad.bind(this)}
            >
                {this.renderLocationMarkers()}
            </GoogleMap>
        ));

        return (
            <div>
                <PageHeaderComponent title="Map" subTitle="Sacaton, Arizona" icon="info" />
                <div className="d-flex flex-column">
                    <ButtonGroup>
                        {this.renderCategories()}
                    </ButtonGroup>
                </div>
                <MapComponent
                    googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=" + this.googleMapsApiKey}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} id="map" />} />
                <div className="list-group" style={{ color: "black" }}>
                    {this.renderLocations()}
                </div>
            </div>
        );
    }

    renderCategories() {
        let jsx = [];
        for (var idx in this.categories) {
            var Cat = this.categories[idx];
            jsx.push(<Button data-category={idx} onClick={this.handleClickFilter.bind(this)} disabled={(idx === this.state.filter)} key={idx}>{Cat.label}</Button>);
        }
        return jsx;
    }

    renderLocationMarkers() {
        var jsx = [];
        var Locations = this.getLocationsList();
        for (var idx in Locations) {
            var L = Locations[idx];
            //var icon = this.categories[L.category].icon;
            var n = parseInt(idx) + 1;
            var label = (n > 26) ? 'a' + (n - 26) : String.fromCharCode(96 + n);
            jsx.push(<Marker
                position={{ lat: L.lat, lng: L.lng }}
                label={label.toUpperCase()}
                title={label + ". " + L.name}
                zIndex={2000 - n}
                key={'marker-' + idx} />);
        }
        return jsx;
    }
    renderLocations() {
        var jsx = [];
        var Locations = this.getLocationsList();
        for (var idx in Locations) {
            var L = Locations[idx];
            //var icon = this.categories[L.category].icon;
            var n = parseInt(idx) + 1;
            var label = (n > 26) ? 'a' + (n - 26) : String.fromCharCode(96 + n);
            var url = 'https://www.google.com/maps/search/' + encodeURIComponent(L.address);
            jsx.push(
                <div className="list-group-item" key={'location-' + idx} id={"location-" + n} data-lat={L.lat} data-lng={L.lng} onClick={this.handleClickLocation.bind(this)}>
                    {label}. <small>{L.type}</small><br />
                    <b>{L.name}</b><br />
                    <small style={{ whiteSpace: 'pre-wrap' }}>{L.address}</small>
                    <div className="float-right">
                        <a href={url} className="btn btn-primary btn-sm" target="_blank" >
                            <img src={AssetNavigation} style={{height: '1.5em'}} />
                        </a>
                    </div>
                </div>
            );
        }
        return jsx;
    }

    getLocationsList() {
        var results = [];

        for (var idx in MapLocations) {
            let L = MapLocations[idx];
            if (this.state.filter === "all" || L.category === this.state.filter) {
                results.push(L);
            }

        }
        return results;
    }

    handleClickLocation(e) {
        document.getElementById('map').scrollIntoView();
        this.mapMove({
            lat: parseFloat(e.target.dataset.lat),
            lng: parseFloat(e.target.dataset.lng)
        }, 16);
    }

    handleClickFilter(e) {
        this.setState({
            filter: e.target.dataset.category,
        })
        this.mapMove(this.defaultCenter, this.defaultZoom);
        return false;
    }

    mapMove(center, zoom){
        this.mapContext().setCenter(center);
        this.mapContext().setZoom(zoom);
    }
    mapContext(){
        return this.map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED; //yeah, well fuck off react-google-maps
    }
    onMapLoad(map) {
        this.map = map;
    }
}