import React from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import HtmlComponent from '../components/HtmlComponent';

import defaultSlide from '../img/headers/a.opt.jpg';

export default class DefaultTemplate extends React.Component {
    render() {
        const opts = {
            showThumbs: false,
            infiniteLoop: true,
            autoPlay: true,
            showStatus: false,
            interval: 7000
        }
        return (
            <Carousel {...opts}  >
                {this.renderSliders()}
            </Carousel>
        );
    }

    renderSliders() {
        if (typeof this.props.slides !== 'object' || this.props.slides.length < 1) {
            return [
                <div key={"slide-default"}>
                    <img src={defaultSlide} alt="Home Slider" />
                </div>
            ];
        }
        var elems = [];
        for (var i in this.props.slides) {
            let slide = this.props.slides[i];
            if (slide.image.length > 1) {
                let url = 'https://app.gricready.org/sysimg/' + slide.image;
                elems.push(
                    <div key={"slide-" + i}>
                        <img src={url} alt={slide.displayName} />
                        {slide.content &&
                            <div className="legend"><HtmlComponent html={slide.content} /></div>
                        }
                    </div>
                );
            }
        }


        return elems;
    }
}

/*
 className="header-image--image"
<h2>{slide.displayName}</h2>
                    <HtmlComponent html={slide.content} />
*/