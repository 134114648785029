import React from 'react';
import PageComponent from '../components/PageComponent';

import PageHeaderComponent from '../components/PageHeaderComponent';
import { Button, ButtonGroup } from 'react-bootstrap';

export default class WeatherPage extends PageComponent {

    state = {
        current: 'map.html'
    }

    buttons = {
        "Map": "map.html",
        "Sacaton": "station.html?site=sa",
        "Laveen": "station.html?site=sj",
        "Casa Blanca": "station.html?site=cb"
    }

    handleClick(e) {
        this.setState({ current: e.target.dataset.src })
        return false;
    }

    render() {
        return (
            <div>
                <PageHeaderComponent title="Weather" subTitle="Sacaton, Arizona" icon="info" />
                <div className="d-flex flex-column">
                    <ButtonGroup>
                        {this.renderButtons()}
                    </ButtonGroup>
                </div>
                <div className="page-content">
                    <iframe src={"https://www.arsgric.com/" + this.state.current} title="Weather View" height="600" width="100%" frameBorder="0"></iframe>
                </div>

            </div >
        );
    }

    renderButtons(_) {
        var r = [];
        for (var key in this.buttons) {
            var src = this.buttons[key];
            r.push(<Button onClick={this.handleClick.bind(this)} data-src={src} key={key} disabled={(src === this.state.current)}>{key}</Button>)
        }
        return r;
    }
}