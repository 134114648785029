import React, { Component } from 'react';

export default class PageHeaderComponent extends Component {
    render() {
        let iconStyle = {
            "backgroundImage": "url('https://app.gricready.org/sysimg/" + this.props.iconPath + "')"
        }
        return (
            <div className={"page-header-component " + this.props.type}>
                {this.props.iconPath &&
                    <div className={"page-header-component--icon icon"} style={iconStyle} ></div>
                }
                <div className={"page-header-component--content " + (this.props.iconPath ? '' : 'no-icon')}>
                    <h3 className={this.props.subTitle ? "" : "no-subTitle"}>{this.props.title}</h3>
                    {this.props.subTitle &&
                        <p className="sub-title" dangerouslySetInnerHTML={{ __html: this.props.subTitle }} />
                    }
                </div>
            </div>
        );
    }
}