import axios from 'axios'
import wrapper from 'axios-cache-plugin'

let http = axios.create({
    baseURL: 'https://app.gricready.org/'
});

let VCMS = wrapper(http, {
    
    maxCacheSize: 30,
    ttl: 60000
});
VCMS.__addFilter(/site/)

export default VCMS;