import React from 'react';
import PageComponent from '../components/PageComponent';

import DefaultTemplate from '../templates/DefaultTemplate';
import PageHeaderComponent from '../components/PageHeaderComponent';
import ContentButtonComponent from '../components/ContentButtonComponent';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import HtmlComponent from '../components/HtmlComponent';


export default class Sections extends PageComponent {
    default = {
        headline: '',
        content: 'Loading...'
    }
    state = {
        page: this.default
    }

    componentDidMount() {
        this.updateSection();
    }

    componentDidUpdate() {
        this.updateSection();
    }

    updateSection() {
        if (this.props.location.pathname !== this.state.path) {
            window.scrollTo(0, 0);
            this.setState({ path: this.props.location.pathname });
            this.fetchPage(this.props.location.pathname, this.default);
        }
    }


    render() {
        let page = this.state.page;
        return (
            <DefaultTemplate loc={this.props.location.pathname}>
                <PageHeaderComponent title={page.displayName} subTitle={page.headline} iconPath={page.imgIcon} />

                {(page.content && page.content.length > 0) &&
                    <div className="page-content">
                        <HtmlComponent html={page.content} />
                    </div>
                }
                <div className="dropdown-component--group">
                    <div className="group-lite">
                        {this.renderList(page.children)}
                    </div>
                </div>
                <p className="mt-2 text-center">
                    <Button onClick={this.props.history.goBack}><FontAwesomeIcon icon={faChevronLeft} /> Go Back</Button>
                </p>
            </DefaultTemplate >
        );
    }

    renderList(list) {
        var jsx = [];
        for (var i in list) {
            jsx.push(<ContentButtonComponent title={list[i].displayName} key={list[i].id} page={list[i]} />)
        }
        return jsx;
    }
}