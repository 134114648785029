import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class ContentButtonComponent extends Component {

    render() {
        var page = this.props.page;
        return (
            <div className={"dropdown-component--container " + this.props.color}>
                <Link className={"dropdown-component--button"} to={page.path + '/' + page.name}>
                    {this.props.title}
                    <div className="dropdown-component--arrow arrow-blue"></div>
                </Link>
            </div>
        );
    }
}