import React from 'react';
import PageComponent from '../components/PageComponent';
import { Redirect } from "react-router"

import DefaultTemplate from '../templates/DefaultTemplate';

import WeatherPage from './WeatherPage';
import FilesPage from './FilesPage';
//import ReportPage from './ReportPage';
import MapPage from './MapPage';


export default class Pages extends PageComponent {

    render() {
        let page = this.getPage();
        if (!page) {
            return (<Redirect to="/" />);;
        } else {
            return (
                <DefaultTemplate loc={this.props.location.pathname}>
                    {page}
                </DefaultTemplate>
            );
        }
    }

    getPage() {
        switch (this.props.match.params.page) {
            case 'weather': return (<WeatherPage loc={this.props.location} />);
            case 'files': return (<FilesPage loc={this.props.location} />);
            //case 'report': return (<ReportPage loc={this.props.location} />);
            case 'map': return (<MapPage loc={this.props.location} />);
            default: return false;
        }
    }
}