import React from 'react';
import PageComponent from '../components/PageComponent';
import DefaultTemplate from '../templates/DefaultTemplate';
import IndexButtonComponent from '../components/IndexButtonComponent';


import '../css/Index.css';

export default class IndexPage extends PageComponent {
    state = {
        page: { children: {} }
    }

    componentDidMount() {
        this.fetchPage('/site/data/', {})
    }

    render() {
        let page = this.state.page;
        return (
            <DefaultTemplate loc={this.props.location.pathname} home={true}>
                <ul className="group index-group">
                    {this.renderList(page.children)}
                </ul>
                <div className="clear clearboth"></div>
            </DefaultTemplate>
        );
    }

    renderList(list) {
        var jsx = [];
        for (var i in list) {
            var page = list[i];
            var href = (page.menuImageLink) ? page.menuImageLink : page.path + '/' + page.name;
            jsx.push(<li key={'index-' + page.id}>
                <IndexButtonComponent
                    title={page.displayName}
                    subTitle={page.headline}
                    href={href}
                    iconPath={page.imgIcon} />
            </li >)
        }
        return jsx;
    }
}