import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

//import { Route, HashRouter as Router } from 'react-router-dom';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';
// Import Pages

import IndexPage from './pages/IndexPage';
import Pages from './pages/Pages';
import Sections from './pages/Sections';

const routing = (
    <Router basename={process.env.PUBLIC_URL}>
        <Route path="/" component={IndexPage} exact />
        <Switch>

            <Route path="/page/:page" component={Pages} />
            <Route path="/site/**" component={Sections} />
        </Switch>
    </Router>
);

const startApp = () => {
    ReactDOM.render(routing, document.getElementById('root'));
}

if (!window.cordova) {
    startApp();
} else {
    document.addEventListener('deviceready', startApp, false)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(); //ToDo: register()
