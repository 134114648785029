import React from 'react';
import { Redirect } from 'react-router-dom'
import PageComponent from '../components/PageComponent';

import { Form, InputGroup, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faKey, faSignal } from '@fortawesome/free-solid-svg-icons';

import PageHeaderComponent from '../components/PageHeaderComponent';
//import HtmlComponent from '../components/HtmlComponent';
import VCMS from '../services/VCMS';
//import NavbarCollapse from 'react-bootstrap/NavbarCollapse';

export default class FilesPage extends PageComponent {
    default = {
        content: 'Loading...',
        token: false
    }
    state = {
        page: false,
        disabled: false,
        feedback: false
    }

    componentDidMount() {
        this.setState({
            'username': this.getUsername(),
            'token': this.getToken()
        })
        if (this.state.token) {
            this.fetchPageData('/site/data/files/protected-files', this.default);
        }
    }

    fetchFilesContent() {
    }

    render() {
        return (
            <div>
                <PageHeaderComponent title="Files" subTitle="O.E.M. Internal Files" icon="info" />

                {!this.state.token &&
                    <div>{this.renderLogin()}</div>
                }
                {this.state.token &&
                    <Redirect to="/site/data/files/protected-files" />
                }

            </div>
        );
    }

    handleSubmit(event) {
        this.setState({ disabled: true, feedback: false })
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        let url = '/scripts/xhr/auth.php'
        let params = {
            'action': 'login',
            'username': form.UserName.value,
            'password': form.Password.value,
            'ts': new Date().getTime()
        }
        this.setUsername(form.UserName.value);

        VCMS({
            url: url,
            method: 'get',
            params: params
        }).then(response => {
            if(response.data.error){
                this.setState({ feedback: { type: 'danger', text: response.data.error_message } })
            } else {
                this.setToken(response.data.token);
                this.setState({'token': response.data.token})
            }
        })
            .catch(error => {
                console.log('error', error);
                this.setState({ feedback: { type: 'danger', text: 'Sorry, network error.', icon: faSignal } })
            })
            .finally(() => {
                this.setState({ disabled: false })
            });

    }

    renderLogin() {
        return (
            <div className="page-content">
                <Form className="mt-3" onSubmit={this.handleSubmit.bind(this)}>
                    <Form.Group controlId="formBasicEmail">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text> <FontAwesomeIcon icon={faAt} /> </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control name="UserName" type="text" placeholder="Username" required={true} defaultValue={this.state.username} />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text> <FontAwesomeIcon icon={faKey} /> </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control name="Password" type="password" placeholder="Password" required={true} />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="formBasicSubmit" className="text-center">
                        <Button type="submit" variant="success" className="btn-block" disabled={this.state.disabled}>
                            {this.state.disabled ? 'Logging In' : 'Login'}
                        </Button>

                    </Form.Group>

                    {this.state.feedback &&
                        <Alert variant={this.state.feedback.type}>
                            {this.state.feedback.icon && <FontAwesomeIcon icon={this.state.feedback.icon} className="mr-2" />}
                            {this.state.feedback.text}
                        </Alert>
                    }
                </Form>
            </div>
        )
    }

    renderContent() {
        return (
            <div className="page-content">

            </div>
        );
    }
}