import { Component } from 'react';
import VCMS  from '../services/VCMS';


export default class PageComponent extends Component {
    page = null;


    fetchPage(page, d) {
        this.page = page;
        this.fetchPageUpdate(page);
        if (localStorage.getItem('page.' + page)) {
            return JSON.parse(localStorage.getItem('page.' + page));
        } else {
            return d;
        }
    }

    updatePage(page, data) {
        localStorage.setItem('page.' + page, JSON.stringify(data));
        this.setState({ page: data })
    }

    fetchPageUpdate(path) {
        let url = path;
        let params = {
            token: this.getToken()
        }
        VCMS({
            url: url,
            method: 'get',
            params: params
        }).then(res => {
            //console.log(page, res);
            if (typeof res.data.data !== 'undefined') {
                this.updatePage(this.page, res.data.data);
            }
        }).catch(function (e) {
            console.log(e);
        });
    }

    getToken(){
        return localStorage.getItem('token') || false;
    }

    setToken(token){
        localStorage.setItem('token', token);
    }

    getUsername(){
        return localStorage.getItem('username') || "";
    }

    setUsername(username){
        localStorage.setItem('username', username);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
}