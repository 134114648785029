import React from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/App.css';
import Logo from '../img/logo350.png';
import { Link } from 'react-router-dom';
import VCMS from '../services/VCMS';

import HomeSlidersComponent from '../components/HomeSlidersComponent';

export default class DefaultTemplate extends React.Component {

    state = {
        sliders: []
    };

    componentDidMount() {
        if (this.props.home) {
            VCMS({
                url: '/site/home-screen-sliders',
                method: 'get'
            }).then(res => {
                if (typeof res.data.data !== 'undefined') {
                    //console.log('home-screen-sliders', res.data.data.children);
                    this.setState({ sliders: res.data.data.children })
                } else {
                    console.log('no home sliders found')
                }
            }).catch(function (e) {
                console.log(e);
            });
        }
    }

    render() {
        const current_path = this.props.loc;
        return (
            <div id="app">
                <div id="wrapper">
                    {this.props.home &&
                        <header className="home_header">
                            <div className="header-image--container">
                                <HomeSlidersComponent slides={this.state.sliders}/>
                            </div>
                            <div id="logo--container">
                                <Link to="/"><img src={Logo} alt="GRIC OEM Logo" id="logo" /></Link>
                            </div>

                        </header>
                    }
                    {!this.props.home &&
                        <header className="default_header">
                            <div className="header-image--container">

                            </div>
                            <div id="logo--container">
                                <Link to="/"><img src={Logo} alt="GRIC OEM Logo" id="logo" /></Link>
                            </div>

                        </header>
                    }
                    <Container id="content">
                        {this.props.children}
                    </Container>
                </div>
                <footer>

                    <div className="footer-secondary">
                        <Link to="/site/other/terms-conditions">Terms & Conditions</Link> | <Link to="/site/other/privacy-policy">Privacy Policy</Link>
                    </div>

                    <div className="footer-primary">
                        {current_path !== '/' &&
                            <Link to="/" className="footer--icon icon icon-home"></Link>
                        }
                        {current_path !== '/page/user' &&
                            //<Link to="/page/user" className="footer--icon icon icon-user"></Link>
                            <i/>
                        }
                        {current_path !== '/site/data/contact' &&
                            <Link to="/site/data/contact" className="footer--icon icon icon-phone"></Link>
                        }
                        {current_path !== '/page/map' &&
                            <Link to="/page/map" className="footer--icon icon icon-location"></Link>
                        }
                    </div>


                </footer>
            </div>
        )
    }

    
}