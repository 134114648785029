import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class IndexButtonComponent extends Component {
    render() {
        let iconStyle = {
            "backgroundImage": "url('https://app.gricready.org/sysimg/" + this.props.iconPath + "')"
        }
        return (
                <Link to={this.props.href} className={"index-button-component " + this.props.type}>
                    {this.props.iconPath &&
                        <div className={"index-button-component--icon icon"} style={iconStyle}></div>
                    }
                    <div className="index-button-component--content">
                        <h3 className={this.props.subTitle ? "" : "no-subTitle"}>{this.props.title}</h3>
                    </div>
                </Link>
        );
    }
}